export default {
  key: "HrdcSettingsJob",
  serviceKey: "hrdc",
  name: {
    singular: "Job",
    plural: "Job",
  },
  parents: [
    //
  ],
  add: {
    name: "Job",
  },
  view: {
    name: "Job",
  },
};
