export default {
  key: "HrdcTaxSummitSettingsPrice",
  serviceKey: "hrdc_tax_summit",
  name: {
    singular: "Price",
    plural: "Price",
  },
  parents: [
    //
  ],
  add: {
    name: "Price",
  },
  view: {
    name: "Price",
  },
};
