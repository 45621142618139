export default {
  key: "refundEmailSubmission",
  serviceKey: "hrdc_einvoicing",
  name: {
    singular: "Refund Email (Draft)",
    plural: "Refund Email (Draft)",
  },
  parents: [
    //
  ],
  add: {
    name: "Refund Email (Draft)",
  },
  view: {
    name: "Refund Email (Draft)",
  },
};
