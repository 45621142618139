export default {
  key: "HrdcTpdiySettingsXero",
  serviceKey: "hrdc_tpdiy",
  name: {
    xero_token: "XERO Token",
  },
  parents: [
    //
  ],
  add: {
    name: {
      xero_token: "",
    },
  },
  view: {
    name: "XERO Token",
  },
};
