export default {
  key: "HrdcSettingsXero",
  serviceKey: "hrdc",
  name: {
    xero_token: "XERO Token",
  },
  parents: [
    //
  ],
  add: {
    name: {
      xero_token: "",
    },
  },
  view: {
    name: "XERO Token",
  },
};
