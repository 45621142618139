export default {
  key: "refundEmailApproval2",
  serviceKey: "hrdc_tpdiy",
  name: {
    singular: "Refund Email Approval 2",
    plural: "Refund Email Approval 2",
  },
  parents: [
    //
  ],
  add: {
    name: "Refund Email Approval 2",
  },
  view: {
    name: "Refund Email Approval 2",
  },
};
