export default {
  key: "manualInvoice",
  serviceKey: "hrdc_tax_summit",
  name: {
    singular: "Manual Invoice To HRDC",
    plural: "Manual Invoice To HRDC",
  },
  parents: [
    //
  ],
  add: {
    name: "Manual Invoice to HRDC",
  },
  view: {
    name: "View Application",
  },
};
