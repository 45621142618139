<template>
  <div
    ref="table"
    :layout="layout"
    :apiUrl="apiUrl"
    :paginationSize="paginationSize"
    :autoColumns="autoColumns"
    :columns="columns"
    :paginationSizeSelector="paginationSizeSelector"
    :modelKey="modelKey"
  ></div>
</template>

<script>
import { TabulatorFull as Tabulator } from "tabulator-tables"; //import Tabulator library
import Api from "@/objects/api";
import store from "@/store";
import Model from "@/objects/model";
import Service from "@/objects/service";

export default {
  name: "BreadTabulator",
  props: {
    apiUrl: {
      type: String,
      default: "",
    },
    layout: {
      type: String,
      default: "",
    },
    paginationSize: {
      type: Number,
      default: 10,
    },
    autoColumns: {
      type: Boolean,
      default: false,
    },
    columns: {
      type: Array,
      default: () => [],
    },
    paginationSizeSelector: {
      type: Array,
      default: () => [10, 20, 30, 40, 50],
    },
    modelKey: {
      type: String,
      default: "",
    },
    columnSelectionDisabledCount: {
      type: Number,
      default: 0,
    },
    responsiveLayout: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    tabulator: null, //variable to hold your table
    api: new Api(),
    model: new Model(),
    service: new Service(),
    tableResponseStatus: "",
    condition: "",
    tableTotalRows: 0,
    tableCurrentPage: 1,
  }),
  created() {
    if (this.model.serviceKey) this.service.set("key", this.model.serviceKey);
  },
  mounted() {
    const tabulatorOptions = {
      pagination: true,
      layout: this.layout,
      responsiveLayout: this.responsiveLayout,
      frozenRows: true,
      layoutColumnsOnNewData: true,
      validationMode: "highlight",
      resizableColumnFit: true,
      paginationMode: "remote", //enable remote pagination
      ajaxURL: this.apiUrl, //set url for ajax request
      ajaxConfig: {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + store.state.auth.data.token,
        },
      },
      sortMode: "remote", //enable remote sort
      filterMode: "remote", //enable remote filter
      paginationSize: this.paginationSize, //optional parameter to request a certain number of rows per page
      paginationButtonCount: 3,
      autoColumns: this.autoColumns,
      columns: this.columns,
      paginationSizeSelector: this.paginationSizeSelector,
      movableColumns: true,
      placeholder: "No Record Found",
      footerElement: "<span id='crm_footer_count'></span>",
      columnHeaderSortMulti: true,
      ajaxResponse: function (url, params, response) {
        // Must configure with server side response
        let last_page = response.info.totalPages;
        let totalItems = response.info.totalItems;
        const page = params.page || 1;
        const pageSize = params.size || 10;
        const startIndex = (page - 1) * pageSize;
        // Modify the response data to include row numbers
        const rows = response.data || [];
        rows.forEach((row, index) => {
          row.rownum = startIndex + index + 1;
        });

        return {
          data: rows,
          last_page: last_page,
          last_row: totalItems,
        };
      },
      paginationCounter: function (
        _pageSize,
        _currentRow,
        currentPage,
        totalRows,
        totalPages
      ) {
        return `showing ${currentPage} - ${totalPages} pages, ${totalRows} results found`;
      },
    };

    // Add grouping if is trashed tab.
    if (this.$route.query.viewId == "trashed") {
      tabulatorOptions.groupBy = (data) => {
        let date = new Date(data.timestampDeleted);
        let year = date.getFullYear();
        let month = ("0" + (date.getMonth() + 1)).slice(-2);
        let day = ("0" + date.getDate()).slice(-2);
        return `${year}-${month}-${day}`; // Group by "YYYY-MM"
      };

      tabulatorOptions.groupHeader = (value, count) => {
        return (
          value +
          "<span style='color:#d00; margin-left:10px;'>(" +
          count +
          " application)</span>"
        );
      };
    }

    this.tabulator = new Tabulator(this.$refs.table, tabulatorOptions);
    this.tabulator.on("cellEdited", (cell) => {
      this.updateCellChanges(cell);
    });
    this.tabulator.on("dataProcessed", (data) => {
      return this.$emit("response", data);
    });
    this.tabulator.on("pageSizeChanged", (pagesize) => {
      this.$emit("getCurrentPageSize", pagesize); // emit to parent
    });
    this.tabulator.on("dataSorting", (sorters) => {
      this.$emit("getCurrentSort", sorters);
    });
    this.tabulator.on("columnResized", (column) => {
      this.$emit("getCurrentColumnWidth", column); // emit to parent
    });
    this.tabulator.on("pageLoaded", () => {
      var pageSize = this.tabulator.getPageSize();
      var pageno = this.tabulator.getPage();
      this.updateUrlParams(pageno, pageSize);
    });
    // get the selected rows and emit to parent
    this.tabulator.on("rowSelectionChanged", (data) => {
      this.$emit("getSelectedRows", data); // emit to parent
    });
    this.tabulator.on("dataLoadError", (error) => {
      this.$emit("getDataLoadErrorCode", error);
    });
  },
  methods: {
    getFilterCondition(filterData) {
      this.tabulator.setFilter(filterData);
    },
    setAdvancedFilterConditions(advanceFilterParams) {
      this.tabulator.setData(this.apiUrl, {
        advanceFilterParam: JSON.stringify(advanceFilterParams),
      });
    },
    applyColumnSelection(selectedColumns) {
      let defaultColumnsFalse = this.columns.filter((column) => {
        if (column.showByDefault == false) {
          return column;
        }
      });

      if (
        selectedColumns.length ==
        this.columns.length -
          (this.columnSelectionDisabledCount + defaultColumnsFalse.length)
      ) {
        // hide if all default false columns
        this.tabulator.setColumns(this.columns);
        for (let column in defaultColumnsFalse) {
          this.tabulator.hideColumn(defaultColumnsFalse[column].field);
        }
        return;
      }

      for (let column in this.columns) {
        if (selectedColumns.includes(this.columns[column].field)) {
          this.tabulator.showColumn(this.columns[column].field);
        } else {
          this.tabulator.hideColumn(this.columns[column].field);
        }
      }
    },
    updateCellChanges(cell) {
      this.$emit("updateEditedCellData", cell); // emit to parent
    },
    callApiUrl(setApiUrl) {
      this.tabulator.setData(setApiUrl);
    },
    getCurrentApiUrl() {
      let currentApiUrl = this.tabulator.getAjaxUrl();
      console.log("currentApiUrl", currentApiUrl);
    },
    callSavedPageSize(pageSize) {
      this.tabulator.setPageSize(pageSize);
    },
    callSavedColumnSort(columnSort) {
      this.tabulator.setSort(columnSort);
    },
    callSavedHeaderWidth(columnWidth) {
      if (columnWidth.length == 0) {
        this.columns.forEach((column) => {
          column.width = "";
        });
      }

      for (let column in this.columns) {
        for (let key in columnWidth) {
          if (this.columns[column].field === columnWidth[key].field) {
            this.columns[column].width = columnWidth[key].width;
          }
        }
      }
    },
    setCurrentPagNumber(pageNo) {
      this.tabulator.setPage(pageNo);
    },
    setCurrentPagSize(pageSize) {
      this.tabulator.setPageSize(pageSize);
    },
    //BOC:[update table data]
    updateTableRowData(rowId, rowname, rowvalue) {
      this.tabulator.updateData([{ id: rowId, [rowname]: rowvalue }]);
    },
    // clear table data
    clearTableData() {
      this.tabulator.setData([]);
      this.tabulator.clearData();
    },
    // Function to update the URL with page number and page size
    updateUrlParams(page, pageSize) {
      const url = new URL(window.location);
      url.searchParams.set("page", page);
      url.searchParams.set("pageSize", pageSize);
      window.history.pushState({}, "", url);
    },
    updateUrlVueRouter(page, pageSize) {
      this.$router.push({ query: { page: page, pageSize: pageSize } });
    },
  },
};
</script>
