export default {
  key: "claimApproved",
  serviceKey: "hrdc_tax_summit",
  name: {
    singular: "Claim Approved From HRDC",
    plural: "Claim Approved From HRDC",
  },
  parents: [
    //
  ],
  add: {
    name: "Claim Approved From HRDC",
  },
};
