<template>
  <div>
    <div v-if="modules.length < 1" class="grey--text">
      No available modules.
    </div>
    <v-row v-else dense>
      <template v-for="(module, index) of modules">
        <template v-if="module.href">
          <v-col cols="12" md="4" lg="3" xl="2" :key="index">
            <v-card
              class="fill-height"
              :href="module.href"
              :disabled="
                module.disabled ||
                (module.isAllowed !== undefined ? !module.isAllowed : false)
              "
              target="_blank"
            >
              <v-card-actions>
                <v-btn text color="primary" block :disabled="module.disabled">{{
                  module.name
                }}</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </template>
        <template v-else-if="module.type == 'divider'">
          <v-col cols="12" :key="index"></v-col>
        </template>
        <template v-else-if="module.route">
          <v-col
            cols="12"
            md="4"
            lg="3"
            xl="2"
            :key="index"
            v-if="module.route"
            :class="{ 'd-none': !module.isAllowed }"
          >
            <v-card
              class="fill-height"
              :to="module.route"
              :disabled="
                module.disabled ||
                (module.isAllowed !== undefined ? !module.isAllowed : false)
              "
            >
              <v-card-actions>
                <v-btn text color="primary" block :disabled="module.disabled">{{
                  module.name
                }}</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </template>
        <template v-else>
          <v-col cols="12" :key="index">{{ module.name }}</v-col>
        </template>
      </template>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    modules: {
      type: Array,
    },
  },
};
</script>
