export default {
  key: "HrdcTpdiySettingsPrice",
  serviceKey: "hrdc_tpdiy",
  name: {
    singular: "Price",
    plural: "Price",
  },
  parents: [
    //
  ],
  add: {
    name: "Price",
  },
  view: {
    name: "Price",
  },
};
