export default {
  state: {
    data: {
      applicationUuid: null,
      stageId: null,
      viewId: null,
    },
  },
  getters: {},
  actions: {},
  mutations: {
    assignHrdcEinvoicingData(state, data) {
      state.data = data;
    },
  },
};
