export default {
  key: "refundEmailApproval1",
  serviceKey: "hrdc_tax_summit",
  name: {
    singular: "Refund Email Approval 1",
    plural: "Refund Email Approval 1",
  },
  parents: [
    //
  ],
  add: {
    name: "Refund Email Approval 1",
  },
  view: {
    name: "Refund Email Approval 1",
  },
};
