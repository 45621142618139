export default {
  key: "enrollmentDetail",
  serviceKey: "hrdc_tax_summit",
  name: {
    singular: "Enrollment Details (New/Renewal)",
    plural: "Enrollment Details (New/Renewal)",
  },
  parents: [
    //
  ],
  add: {
    name: "Enrollment Details (New/Renewal)",
  },
};
