import { createPopper } from "@popperjs/core";
import "bootstrap/dist/js/bootstrap.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "@visual-filter/vue2/dist/styles.css";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import i18n from "./plugins/i18n";
import Tabulator from "./plugins/tabulator";
import "@/mixins/locale";
import "@/mixins/route";
import "@/mixins/string";
import _ from "lodash";
import moment from "moment";
/* import the fontawesome core */
import { library } from "@fortawesome/fontawesome-svg-core";

/* import font awesome icon component */
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

/* import specific icons */
import { fas } from "@fortawesome/free-solid-svg-icons";

/* add icons to the library */
library.add(fas);

//BOC
import { version } from "./../package.json";
Vue.prototype.$version = version;
//EOC
//BOC
Vue.prototype.$app = {
  name: process.env.VUE_APP_NAME,
};
//EOC
//BOC:[service]
var service = {
  crm: process.env.VUE_APP_SERVICE_URL_CRM,
  // developer: process.env.VUE_APP_SERVICE_URL_DEVELOPER,
  hrdc: process.env.VUE_APP_SERVICE_URL_HRDC,
  hrdc_einvoicing: process.env.VUE_APP_SERVICE_URL_HRDC_EINVOICING,
  hrdc_tax_summit: process.env.VUE_APP_SERVICE_URL_HRDC_TAX_SUMMIT,
  hrdc_tpdiy: process.env.VUE_APP_SERVICE_URL_HRDC_TPDIY,
  // lead: process.env.VUE_APP_SERVICE_URL_LEAD,
  log: process.env.VUE_APP_SERVICE_URL_LOG,
  sso: process.env.VUE_APP_SERVICE_URL_SSO,
};
Vue.prototype.$service = service;
//EOC
//BOC:[env]
Vue.prototype.$env = process.env;
//EOC
//BOC:[api]
import axios from "axios";
Vue.prototype.$axios = axios;
//EOC
import hrdcFunction from "@/objects/hrdcFunction";
//BOC
Vue.prototype.$_ = _;
//EOC
//BOC
Vue.prototype.$moment = moment;
//EOC
//BOC:[common components]
Vue.component(
  "ABreadcrumb",
  require("./components/common/ABreadcrumb.vue").default
);
Vue.component(
  "ADashboard",
  require("./components/common/ADashboard.vue").default
);
Vue.component("ADevNote", require("./components/common/ADevNote.vue").default);
Vue.component("AError", require("./components/common/AError.vue").default);
Vue.component("ALoader", require("./components/common/ALoader.vue").default);
Vue.component(
  "APageTitle",
  require("./components/common/APageTitle.vue").default
);
Vue.component("ATab", require("./components/common/ATab.vue").default);
Vue.component(
  "BreadDataTable",
  require("./components/common/BreadDataTable.vue").default
);
Vue.component(
  "BreadDataTableFilterable",
  require("./components/common/BreadDataTableFilterable.vue").default
);
Vue.component(
  "BreadDataTableView",
  require("./components/common/BreadDataTableView.vue").default
);
Vue.component(
  "BreadDataTableViewable",
  require("./components/common/BreadDataTableViewable.vue").default
);
Vue.component(
  "BreadDataView",
  require("./components/common/BreadDataView.vue").default
);
Vue.component(
  "BreadForm",
  require("./components/common/BreadForm.vue").default
);
Vue.component(
  "BreadHeader",
  require("./components/common/BreadHeader.vue").default
);
Vue.component(
  "BreadTabChild",
  require("./components/common/BreadTabChild.vue").default
);
Vue.component(
  "BreadTabChildV2",
  require("./components/common/BreadTabChildV2.vue").default
);
Vue.component(
  "BreadTabIntermediate",
  require("./components/common/BreadTabIntermediate.vue").default
);
Vue.component(
  "MDataTable",
  require("./components/mockup/MDataTable.vue").default
);
Vue.component("MNote", require("./components/mockup/MNote.vue").default);
Vue.component("MSelect", require("./components/mockup/MSelect.vue").default);
Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.component(
  "BreadTabulator",
  require("./components/common/BreadTabulator.vue").default
);
Vue.component(
  "BreadTabulatorFilterable",
  require("./components/common/BreadTabulatorFilterable.vue").default
);
Vue.component(
  "BreadTabulatorSearchable",
  require("./components/common/BreadTabulatorSearchable.vue").default
);
Vue.component(
  "BreadTabulatorAdvanceFilterable",
  require("./components/common/BreadTabulatorAdvanceFilterable.vue").default
);
Vue.component(
  "BreadTabulatorSelectColumn",
  require("./components/common/BreadTabulatorSelectColumn.vue").default
);
Vue.component(
  "BreadTabulatorForm",
  require("./components/common/BreadTabulatorForm.vue").default
);
Vue.component(
  "BreadTabulatorView",
  require("./components/common/BreadTabulatorView.vue").default
);

Vue.component(
  "BreadTabulatorDataView",
  require("./components/common/BreadTabulatorDataView.vue").default
);
Vue.component(
  "BreadTabulatorTabChild",
  require("./components/common/BreadTabulatorTabChild.vue").default
);
Vue.component(
  "BreadTabulatorTabChildV2",
  require("./components/common/BreadTabulatorTabChildV2.vue").default
);
Vue.component(
  "BreadTabulatorTabIntermediate",
  require("./components/common/BreadTabulatorTabIntermediate.vue").default
);

Vue.component(
  "BreadTabulatorViewLatest",
  require("./components/common/BreadTabulatorViewLatest.vue").default
);

Vue.component(
  "BreadTabulatorViewLatestV2",
  require("./components/common/BreadTabulatorViewLatestV2.vue").default
);

Vue.component(
  "ATabulatorLoder",
  require("./components/common/ATabulatorLoder.vue").default
);
Vue.component(
  "BreadTabulatorExport",
  require("./components/common/BreadTabulatorExport.vue").default
);
Vue.component(
  "BreadTabulatorExportAll",
  require("./components/common/BreadTabulatorExportAll.vue").default
);

Vue.component(
  "TestRefresh",
  require("./components/common/TestRefresh.vue").default
);
Vue.component(
  "TestRefresh2",
  require("./components/common/TestRefresh2.vue").default
);

// BOC:[HRDC]
Vue.component(
  "HrdcLoader",
  require("./services/hrdc/components/HrdcLoader.vue").default
);
//EOC

//EOC
//BOC:[custom tabs]
Vue.component(
  "TabConsoleLeadListLead",
  require("@/tabs/TabConsoleLeadListLead.vue").default
);
//EOC
//BOC:[meta]
import VueMeta from "vue-meta";
Vue.use(VueMeta);
//EOC

//BOC:[visual filter]
import VueVisualFilter from "@visual-filter/vue2";
Vue.use(VueVisualFilter);
//EOC

//BOC:[Vuetify Tiptap WYSIWYG Text Editor]
import { TiptapVuetifyPlugin } from "tiptap-vuetify";
import "tiptap-vuetify/dist/main.css";
Vue.use(TiptapVuetifyPlugin, {
  vuetify,
  // optional, default to 'md' (default vuetify icons before v2.0.0)
  iconsGroup: "mdi",
});
//EOC

Vue.config.productionTip = false;

Vue.mixin(hrdcFunction);

new Vue({
  router,
  store,
  vuetify,
  i18n,
  Tabulator,
  createPopper,
  library,
  FontAwesomeIcon,
  render: (h) => h(App),
}).$mount("#app");
