export default {
  key: "HrdcTaxSummitSettingsEvents",
  serviceKey: "hrdc_tax_summit",
  name: {
    singular: "Event",
    plural: "Event",
  },
  parents: [
    //
  ],
  add: {
    name: "Event",
  },
  view: {
    name: "Event",
  },
};
