<template>
  <v-snackbar
    v-model="show"
    :timeout="snackbar.timeout"
    :value="true"
    :color="snackbar.color"
    bottom
  >
    {{ snackbar.text }}
  </v-snackbar>
</template>

<script>
//Reference: https://dev.to/stephann/how-to-create-a-global-snackbar-using-nuxt-vuetify-and-vuex-1bda
import { mapState } from "vuex";
export default {
  components: {
    //
  },
  computed: mapState({
    snackbar: (state) => state.snackbar.data,
  }),
  props: {
    //
  },
  data: () => ({
    show: false,
  }),
  watch: {
    //
  },
  created() {
    this.$store.subscribe((mutation) => {
      if (mutation.type === "updateSnackbar") {
        this.show = true;
      }
    });
  },
  mounted() {
    //
  },
  methods: {
    //
  },
};
</script>

<style>
.error {
  background-color: #ff5252 !important;
  border-color: #ff5252 !important;
}
.success {
  background-color: #66bb6a !important;
  border-color: #66bb6a !important;
}
.info {
  background-color: #2196f3 !important;
  border-color: #2196f3 !important;
}
</style>