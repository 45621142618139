export default [
  {
    name: "Applications",
    icon: "mdi-file-document-outline",
    route: {
      name: "HrdcTpdiyApplication",
      params: { modelKey: "application" },
      query: {
        viewId: "all",
      },
    },
  },
  // {
  //   name: "Manual/HRDC Invoice",
  //   slug: "manual-invoice",
  //   icon: "mdi-receipt-text-outline",
  //   route: {
  //     name: "HrdcTpdiyManualInvoiceToHrdcBulk",
  //     params: { modelKey: "manualInvoiceToHrdc" },
  //   },
  //   disabledForRoles: ["taxpod-salesperson"],
  // },
  {
    name: "Refund AP to CIMB",
    slug: "refund-ap-to-cimb",
    icon: "mdi-bank-outline",
    route: {
      name: "HrdcTpdiyRefundEmailApToCimb",
      params: { modelKey: "refundApToCimb" },
      query: {
        viewId: "all",
      },
    },
    disabledForRoles: ["taxpod-salesperson"],
  },
  // {
  //   name: "Submission Date",
  //   icon: "mdi-calendar-multiselect",
  //   route: {
  //     name: "HrdcTpdiyApplicationSubmissionDate",
  //     params: { modelKey: "submissionDate" },
  //     query: {
  //       viewId: "all",
  //     },
  //   },
  //   disabledForRoles: ["taxpod-salesperson"],
  // },
  // {
  //   name: "Report Analysis",
  //   icon: "mdi-chart-line",
  //   route: {
  //     name: "HrdcTpdiyReportAnalysis",
  //     params: { modelKey: "reportAnalysis" },
  //     query: {
  //       viewId: "all",
  //     },
  //   },
  //   disabledForRoles: ["taxpod-salesperson"],
  // },
  {
    name: "Settings",
    icon: "mdi-cog-outline",
    route: {
      name: "HrdcTpdiySettings",
      params: { modelKey: "hrdcSettings" },
    },
    disabledForRoles: ["taxpod-salesperson"],
  },
];
