export default [
  {
    name: "Roles",
    icon: "mdi-database",
    route: {
      name: "PageServiceModelBrowse",
      params: { serviceKey: "sso", modelKey: "internalRole" },
      query: {
        viewId: "all",
      },
    },
    disabledForRoles: ["taxpod-salesperson"],
  },
  {
    name: "Users",
    icon: "mdi-database",
    route: {
      name: "PageServiceModelBrowse",
      params: { serviceKey: "sso", modelKey: "internalUser" },
      query: {
        viewId: "all",
      },
    },
    disabledForRoles: ["taxpod-salesperson"],
  },
];
