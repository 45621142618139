import stages from "@/objects/stages";

export default class HrdcData {
  constructor() {
    this.stagePriority = null;
    this.stageSlug = null;
    this.stageIndex = null;
    this.stageSlugToStageName = null;

    const allStage = [];
    const allStagePriority = [];
    const allStageSlug = [];
    const allStageIndex = [];
    const allStageSlugToStageName = [];

    for (let i = 0; i < stages.length; i++) {
      let resultData = stages[i];
      let stageName = resultData.name.toUpperCase().replace(/ /g, "_");
      let stageNameSlug = resultData.nameSlug;

      allStage[stageName] = resultData.id;
      allStagePriority[stageNameSlug] = resultData.id;
      allStageSlug[stageNameSlug] = resultData.stagePriority;
      allStageIndex[resultData.id] = resultData.stagePriority;
      allStageSlugToStageName[stageNameSlug] = resultData.name;
    }

    this.stagePriority = allStagePriority;
    this.stageSlug = allStageSlug;
    this.stageIndex = allStageIndex;
    this.stageSlugToStageName = allStageSlugToStageName;
  }
}
