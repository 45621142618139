<template>
  <v-app>
    <v-app-bar
      app
      color="#063058"
      dark
      dense
      outlined
      elevation="0"
      elevate-on-scroll
      height="60"
    >
      <v-btn
        text
        class="px-1 border-0"
        @click="redirectDashboard('PageConsoleDashboard')"
        exact
        exact-active-class="primary"
        >{{ $app.name }}</v-btn
      >
      <v-chip
        class="ml-1"
        x-small
        color="blue darken-4"
        style="font-size: 8px"
        >{{ $version }}</v-chip
      >
      <v-progress-linear
        v-if="api.isLoading && services.length < 1"
        indeterminate
        color="white"
        style="width: 30px"
        rounded
        class="mx-2"
      ></v-progress-linear>
      <div v-else class="d-flex align-center">
        <div class="px-1">/</div>
        <v-menu open-on-hover bottom offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              text
              dark
              elevation="0"
              v-bind="attrs"
              v-on="on"
              class="px-1"
              @click="redirectDashboard('PageServiceDashboard')"
            >
              {{ currentService ? currentService.name : "Services" }} ▼
            </v-btn>
          </template>
          <v-list dense nav>
            <v-list-item
              v-for="(item, index) in services"
              :key="index"
              :to="item.route"
              :disabled="!item.isEnabled || !item.isAllowed"
              :class="{ 'd-none': !item.isEnabled || !item.isAllowed }"
            >
              <v-list-item-title>{{ item.name }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <!--BOC:[module]-->
      <div v-if="currentService && menu" class="d-flex align-center">
        <div class="px-1">/</div>
        <v-menu open-on-hover bottom offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              text
              dark
              elevation="0"
              v-bind="attrs"
              v-on="on"
              class="px-1"
            >
              Modules ▼
            </v-btn>
          </template>
          <v-list dense nav :min-width="250">
            <template v-for="(item, index) in menu">
              <div :key="index">
                <template v-if="item.type == 'divider'">
                  <v-divider class="my-1" color="grey"></v-divider>
                </template>
                <v-list-item
                  v-else
                  :to="item.route"
                  :class="{ 'd-none': !item.isAllowed }"
                  :disabled="
                    item.isAllowed !== undefined ? !item.isAllowed : false
                  "
                >
                  <v-list-item-icon v-if="item.icon" class="mr-2">
                    <v-icon>{{ item.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-subtitle v-if="!item.route">{{
                    item.name
                  }}</v-list-item-subtitle>
                  <v-list-item-title v-else>
                    {{ item.name }}
                    &nbsp;
                    <v-badge
                      v-if="item.count && item.count !== undefined"
                      :content="item.count"
                      color="green"
                    ></v-badge>
                  </v-list-item-title>
                </v-list-item>
              </div>
            </template>
          </v-list>
        </v-menu>
      </div>
      <v-spacer />
      <AppHeaderProfile />
    </v-app-bar>
    <v-main class="grey lighten-3">
      <router-view :key="$route.fullPath" />
    </v-main>
  </v-app>
</template>

<script>
import Api from "@/objects/api";
import { mapState } from "vuex";
import configMenu from "@/config/menu";
import hrdcFunction from "@/objects/hrdcFunction";
import AppHeaderProfile from "@/components/layouts/AppHeaderProfile";

export default {
  mixins: [hrdcFunction],
  components: {
    AppHeaderProfile,
  },
  computed: {
    ...mapState({
      auth: (state) => state.auth.data,
      debugMode: (state) => state.debugMode.data,
      services: (state) => state.service.data.services,
      currentService: (state) => state.service.data.currentService,
    }),
  },
  data: () => ({
    api: new Api(),
    apiGetUser: new Api(),
    menu: null,
    serviceKey: null,
  }),
  watch: {
    "$route.params.serviceKey": {
      handler: function (newServiceKey, oldServiceKey) {
        if (newServiceKey != oldServiceKey) {
          this.$store.dispatch(
            "selectCurrentServiceByServiceKey",
            newServiceKey
          );
          if (newServiceKey) this.updateMenu(newServiceKey);
          this.serviceKey = newServiceKey;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    this.apiGetUser.setUrl(`${this.$service.sso}/v1/en/auth/me`);
    this.apiGetUser.setCallbackCompleted((response) => {
      try {
        const { status, data } = response;
        if (status) {
          this.$store.commit("updateAuth", data);
          this.api.fetch();
        }
      } catch (err) {
        console.log(err);
      }
    });
    this.apiGetUser.fetch();

    this.api.setMethod(`POST`);
    this.api.setUrl(`${this.$service.crm}/v1/en/console/dashboard`);
    var internalRoleSlugs = this.$_.map(this.auth.roles, "slug");
    this.api.setParams({
      internalRoleSlugs: JSON.stringify(internalRoleSlugs),
    });
    this.api.setCallbackCompleted((response) => {
      var services = response;
      this.$store.dispatch("updateServices", services);
    });
  },
  methods: {
    updateMenu(serviceKey) {
      const targetMenu = configMenu[serviceKey];
      if (!targetMenu) {
        this.menu = null;
        return;
      }
      this.menu = this.$_.clone(targetMenu);
      this.menu.push({
        type: "divider",
      });
      this.menu.push({
        name: "Dashboard",
        route: {
          name: "PageServiceDashboard",
          params: { serviceKey: serviceKey },
        },
      });

      const data = {
        roles: this.auth.roles,
        menu: this.menu,
      };

      this.configMenu(data);
    },
    redirectDashboard(routeName) {
      switch (routeName) {
        case "PageServiceDashboard":
          if (
            this.currentService &&
            this.serviceKey !== this.currentService.key
          ) {
            this.$router.push({
              name: routeName,
              params: { serviceKey: this.currentService.key },
            });
          }
          break;

        case "PageConsoleDashboard":
          if (this.$route.name !== routeName) {
            this.$router.push({ name: routeName });
            break;
          }
      }
    },
  },
};
</script>
