export default {
  key: "refundEmailArToAp",
  serviceKey: "hrdc_tpdiy",
  name: {
    singular: "Refund Email AR to AP",
    plural: "Refund Email AR to AP",
  },
  parents: [
    //
  ],
  add: {
    name: "Refund Email AR to AP",
  },
  view: {
    name: "Refund Email AR to AP",
  },
};
