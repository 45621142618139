export default {
  key: "paymentReceived",
  serviceKey: "hrdc_tax_summit",
  name: {
    singular: "Payment Received From HRDC",
    plural: "Payment Received From HRDC",
  },
  parents: [
    //
  ],
  add: {
    name: "Payment Received From HRDC",
  },
  view: {
    name: "Payment Received From HRDC",
  },
};
