export default {
  key: "financeRefundToClient",
  serviceKey: "hrdc_tpdiy",
  name: {
    singular: "Finance Refund To Client",
    plural: "Finance Refund To Client",
  },
  parents: [
    //
  ],
  add: {
    name: "Finance Refund To Client",
  },
  view: {
    name: "Finance Refund To Client",
  },
};
